.header {
  background-image: url("/assets/images/header_bg.webp");
  background-size: cover;
  background-position: -5px 50%;
  height: 100vh;
  min-height: 700px;
  position: relative;
  -webkit-box-shadow: 0 80px 60px -60px #1a2632 inset;
  -moz-box-shadow: 0 80px 60px -60px #1a2632 inset;
  box-shadow: 0 80px 60px -60px #1a2632 inset;
  position: relative;
}
.header > *:not(.header_shadow):not(.header_overlay) {
  z-index: 3;
}
.faq_button_size {
  font: normal 28px/34px "Poppins" !important;
 text-align: left;
 background: transparent !important;
 color: #29a6ed !important;
 outline: none !important;
 border: none !important;

}
.header_shadow {
  background-image: linear-gradient(180deg, #1a263200, #1a2632);
  width: 100%;
  height: 20%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
}
.header_overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  filter:brightness(0.5)
}
.header_overlay_video {
  width: 100%;
  height: 100%;
  object-fit: cover !important;
 
}
.header_overlay_video_m {
  background-color: black;
  object-fit: contain !important;
}
.header_overlay.header_overlay_play {
  z-index: 100;
  position: fixed;
  filter:brightness(1)
}
.header_overlay iframe {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  display: block;
  width: 100%;
  border: none;
  overflow-y: auto;
  overflow-x: hidden;
}
.video_playing {
  position: fixed;
}
.header_video_btn {
  position: absolute !important;
}
.cursor_video_btn {
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-color: #343a43 !important;
  border: unset !important;
  z-index: 9;
}
.header_video_btn_text {
  bottom: 50px;
  left: 10%;
  color: #ffffff;
  font: 500 10px/40px Poppins;
}
.header_video_play_btn {
  bottom: 100px;
  left: 10%;
}
.header_video_pause_btn {
  top: 50px;
  right: 10%;
  z-index: 999 !important;
}
.custom_navbar {
  padding: 0;
  background: transparent !important;
  top: 0;
  z-index: 99 !important;
  position: fixed;
}
.header *:not(.header_brown_logo) {
  transition: all 0.3s linear;
}
.header .custom_navbar {
  position: fixed;
}
.ant-scrolling-effect .header .custom_navbar {
  width: calc(100% - 17px) !important;
}
.main_logo {
  width: 136px;
  height: 60px;
  position: relative;
  z-index: 9;
}
.header_logo {
  transition: all 0.3s linear;
  object-fit: contain;
  margin-top: -10px;
  display: none;
}

.main_logo.remove_unused_logo > div:first-of-type,
.main_logo.remove_unused_logo > span:first-of-type {
  display: none !important;
}
.header_brown_logo{
  position: absolute;
  opacity: 1;
  left: 0;
  object-fit: fill;
}

.header .main_logo.remove_unused_logo > div:first-of-type,
.header .main_logo.remove_unused_logo > span:first-of-type {
  display: inline-block !important;
  visibility: visible;
  opacity: 1;
}
.header .header_brown_logo {
  visibility: visible;
  opacity: 1;
}

.header .main_logo.remove_unused_logo > div:nth-of-type(2),
.header .main_logo.remove_unused_logo > span:nth-of-type(2) {
  display: none !important;
}
.header .header_logo {
  opacity: 0;
  visibility: hidden;
}

.header.header_menu_bg .main_logo.remove_unused_logo > div:first-of-type,
.header.header_menu_bg .main_logo.remove_unused_logo > span:first-of-type,
.header.header_menu_video .main_logo.remove_unused_logo > div:first-of-type,
.header.header_menu_video .main_logo.remove_unused_logo > span:first-of-type
 {
  display: none !important;
}
.header.header_menu_bg .header_brown_logo,
.header.header_menu_video .header_brown_logo {
  visibility: hidden;
  opacity: 0;
}

.header.header_menu_bg .main_logo.remove_unused_logo > div:nth-of-type(2),
.header.header_menu_bg .main_logo.remove_unused_logo > span:nth-of-type(2),
.header.header_menu_video .main_logo.remove_unused_logo > div:nth-of-type(2),
.header.header_menu_video .main_logo.remove_unused_logo > span:nth-of-type(2) {
  display: inline-block !important;
}
.header.header_menu_bg .header_logo,
.header.header_menu_video .header_logo {
  visibility: visible;
  opacity: 1;
}
.custom_navbar::after {
  /* box-shadow: rgb(0 0 0 / 8%) 0px 1px 12px !important; */
  transform-origin: 50% 0% !important;
  transition-duration: 150ms !important;
  transition-property: opacity, -ms-transform, -webkit-transform, transform !important;
  transition-timing-function: ease !important;
  background: #08151b !important;
  content: "" !important;
  height: 100% !important;
  left: 0px !important;
  position: absolute !important;
  top: 0px !important;
  width: 100% !important;
  z-index: 0 !important;
  opacity: 1 !important;
}
.custom_navbar_open::after {
  box-shadow: rgb(0 0 0 / 8%) 0px 1px 12px !important;
  transition: all 0.3s linear;
  transform-origin: 50% 0% !important;
  transition-duration: 150ms !important;
  transition-property: opacity, -ms-transform, -webkit-transform, transform !important;
  transition-timing-function: ease !important;
  background: #08151b !important;
  content: "" !important;
  height: 100% !important;
  left: 0px !important;
  position: absolute !important;
  top: 0px !important;
  width: 100% !important;
  z-index: 0 !important;
  opacity: 1 !important;
  transform: scaleY(2.8) !important;
}
.header .custom_navbar::after,
.header .custom_navbar_open::after {
  background-color: transparent !important;
}
.header.header_menu_bg .custom_navbar::after,
.header.header_menu_bg .custom_navbar_open::after {
  background: #121c27 !important;
}
.custom_navbar .navbar,
.custom_navbar :global(.navbar) {
  z-index: 1 !important;
  padding: 0;
}
.main_logo img.header_brown_logo{
  /* width: 100%; */
  position: absolute;
  top: 0;
}
@media (orientation: portrait){
  .checkLink{
    display: none;
  }
}
.demo_select {
  position: fixed;
}
.header_video_bg {
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.header_video_bg video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.header_video {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 9 !important;
}
.header_video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* Nav Bar */
.custom_navbar .nav_link,
.custom_navbar p {
  font: 500 20px/22px "Poppins";
  color: #ffffff;
  padding: 0 10px;
  margin-bottom: 0;
  z-index: 9;
}
.custom_navbar .nav_link:focus,
.custom_navbar .nav_link:hover {
  color: #ffffff;
}
.header .custom_navbar .nav_link {
  color: #2f2233;
}
.header .custom_navbar .nav_link:hover {
  color: #29a6ed !important;
  /* font: 500 24px/22px "Poppins"; */
}
.header.header_menu_bg .custom_navbar .nav_link,
.header.header_menu_video .custom_navbar .nav_link {
  color: #ffffff;
}
.custom_navbar .nav_section_home {
  display: none !important;
  padding: 25px 0;
}
.custom_navbar .nav_section {
  padding: 25px 64px;
  z-index: 9;
}
.nav_section_profile_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9; /* Added to bring to front */
}
.nav_section_profile_detail_welcomeText {
  display: flex;
  align-items: center;
  font: normal 12px/12px "Poppins" !important;
  color: #b4a9c0 !important;
  opacity: 1;
  margin-bottom: 0.3rem !important;
}
.header .nav_section_profile_detail_welcomeText {
  color: #786e82 !important;
}
.header.header_menu_video .nav_section_profile_detail_welcomeText,
.header.header_menu_bg .nav_section_profile_detail_welcomeText {
  color: #b4a9c0 !important;
}
.nav_section_profile_detail_welcomeText > span {
  margin-left: 0.3rem;
}
.nav_section_profile_detail_username {
  font: normal 14px/16px "Poppins" !important;
  color: #ffffff !important;
  opacity: 1;
}
.header .nav_section_profile_detail_username {
  color: #2f2233 !important;
}
.header.header_menu_video .nav_section_profile_detail_username,
.header.header_menu_bg .nav_section_profile_detail_username {
  color: #ffffff !important;
}
.nav_section_profile_img {
  width: 32px;
  height: 32px;
  border: 0.2rem solid white;
  border-radius: 50%;
  margin: 0 auto;
  display: block;
  background-color: white;
}
.nav_section_profile_popover > ul {
  list-style: none;
  padding: 0 1rem;
  margin: 0;
}
.nav_section_profile_popover > ul > li {
  font: normal normal 600 14px/16px Poppins !important;
  cursor: pointer;
}
.nav_section_profile_popover > ul > li:last-child {
  border-top: 1px solid #e9e9e9 !important;
  padding-top: 10px;
  margin-top: 10px;
}
.nav_section_search {
  margin: 0 auto;
}
.nav_section_search_mini {
  display: inline-block !important;
  vertical-align: middle !important;
  text-align: left !important;
  transition: -ms-transform 150ms ease 0s, -webkit-transform 150ms ease 0s,
    transform 150ms ease 0s, opacity 50ms ease 20ms,
    pointer-events 0ms ease 150ms !important;
  transform-origin: 50% 0% !important;
  margin: 0px auto !important;
}
.nav_section_search_mini_open {
  display: inline-block !important;
  vertical-align: middle !important;
  text-align: left !important;
  -webkit-transform-origin: 0% 0% !important;
  -ms-transform-origin: 0% 0% !important;
  transform-origin: 0% 0% !important;
  -webkit-transform: scale(2.5, 1.375) translate(-60px, 122px) !important;
  -ms-transform: scale(2.5, 1.375) translate(-60px, 122px) !important;
  transform: scale(2.5, 1.375) translate(-60px, 122px) !important;
  opacity: 0 !important;
  -webkit-transition: -webkit-transform 150ms ease, transform 150ms ease,
    opacity 50ms ease 20ms, visibility 0ms 150ms !important;
  -moz-transition: transform 150ms ease, opacity 50ms ease 20ms,
    visibility 0ms 150ms !important;
  transition: -ms-transform 150ms ease, -webkit-transform 150ms ease,
    transform 150ms ease, opacity 50ms ease 20ms, visibility 0ms 150ms !important;
  pointer-events: none !important;
  visibility: hidden !important;
  will-change: transform, opacity !important;
  -webkit-transform-origin: 50% 0% !important;
  -ms-transform-origin: 50% 0% !important;
  transform-origin: 50% 0% !important;
  margin: 0 auto !important;
  -webkit-transform: scale(2.857142857142857, 1.375) translateY(58px) !important;
  -ms-transform: scale(2.857142857142857, 1.375) translateY(58px) !important;
  transform: scale(2.857142857142857, 1.375) translateY(58px) !important;
}
.nav_section_search_mini_btn {
  padding: 7px;
  background-color: #29a6ed;
  color: #fff;
  border-radius: 40px;
  border: none;
  width: 480px;
  max-width: 100%;
}
.nav_section_search_mini_btn:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  padding: 7px;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: -1;
}
@keyframes flash {
  0% {opacity: 0;}
  50% {opacity: 1;}
  100% {opacity: 0;}
}
.nav_section_search_min_btn_text_new{
  background-color: transparent;
  border-radius: 50px;
  color: #ffffff;
  padding: 10px 15px 15px 15px;
}
.nav_section_search_mini_btn_text_new:not(.no_animation){
  animation: wiggle_main_mobile 5s ease-in-out 4s infinite;
}
@keyframes wiggle_main_mobile {
  0% {
    transform: scale(1);
    color: #fff;
  }
  2% {
    transform: scale(0.95);
    color: #c2c2c2 ;
  }
  4% { transform: scale(1);
    color: #fff;
  }
  5% { transform: translateX(0); }
  15% { transform: translateX(-5.5px); }
  25% { transform: translateX(5.5px); }
  35% { transform: translateX(-3.83px); }
  45% { transform: translateX(3.83px);  }
  55% { transform: translateX(-2.8px); }
  65% { transform: translateX(2.8px); }
  75% { transform: translateX(-2px); }
  85% { transform: translateX(2px); }
  95% { transform: translateX(-1.2px); }
  100% {transform: translateX(0); }
}
.nav_section_search_min_btn_text_new p{
  color: #ffffff;
  margin-bottom: 0;
  animation: super_text_flash 5s ease-in-out 4s infinite;
}
.nav_section_search_min_btn_text_new p.bigger_text{
  font-size: 22px;
}
@media (max-width:425px) {
  .nav_section_search_min_btn_text_new p{
    font-size: 12px;
  }
  .nav_section_search_min_btn_text_new p.bigger_text{
    font-size: 16px;
  }
}
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) {
    .nav_section_search_mini_btn { 
      width: 520px;
    }
}}
.custom_navbar .nav_section_search_mini_btn_text,
.nav_section_search_mini_btn_text_wrap,
.nav_section_search_mini_btn_text_wrap_mobile span {
  font: normal 16px/22px "Poppins";
  letter-spacing: 0px;
  color: #645767;
  text-align: left;
}
.search_animation{
  animation: wiggle 5s ease-in-out 1s infinite;
}
@keyframes wiggle {
  0% {
    transform: scale(1);
    color: #fff;
  }
  2% {
    transform: scale(0.95);
    color: #c2c2c2;
  }
  4% { transform: scale(1);
    color: #fff;
  }
  5% { transform: translateX(0); }
  15% { transform: translateX(-15px); }
  25% { transform: translateX(15px); }
  35% { transform: translateX(-10px); }
  45% { transform: translateX(10px);  }
  55% { transform: translateX(-7.5px); }
  65% { transform: translateX(7.5px); }
  75% { transform: translateX(-5px); }
  85% { transform: translateX(5px); }
  95% { transform: translateX(-2.5px); }
  100% {transform: translateX(0); }
}
/* .button {
  cursor: pointer;
  background-color: #29a6ed;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
} */
.custom_navbar .nav_section_search_mini_btn_text,
.nav_section_search_mini_btn_text_wrap span {
  font: normal 16px/22px "Poppins";
  letter-spacing: 0px;
  color: #ffffff;
  background-color: #29a6ed;
  /* text-align: left; */
}
.nav_section_search_mini_btn_text_wrap,
.nav_section_search_mini_btn_text_wrap_mobile span {
  padding: 0 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: fit-content;
}
.nav_section_search_mini_btn_text_wrap span {
  padding: 0 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.nav_section_search_mini_btn_text_wrap_city {
  max-width: 190px;
}
.nav_section_search_mini_btn_text_wrap span:nth-child(2) {
  border-left: 1px solid #645767;
  border-right: 1px solid #645767;
}
.nav_section_search_mini_btn_text_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.nav_section_search_mini_btn_text_wrap_mobile {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  width: 100%;
}
.nav_section_search_mini_btn_text_wrap_mobile .slash {
  border-right: 1px solid #645767;
}
.custom_navbar .nav_section_search_mini_btn_img {
  width: 32px;
}
.nav_section_search_mini_btn_img img {
  width: 100%;
}
.custom_navbar .nav_section_search_mini_btn_mobile {
  width: 100%;
  margin: 20px auto 0;
}
.header .custom_navbar .nav_section_search_mini_btn_mobile {
  display: none;
}
.nav_section_search_mini_btn_mobile button {
  margin: 0 auto;
}
.nav_section_search_mega {
  width: 824px;
  height: 109px;
  padding: 25px;
  background-color: #ffffff;
  border-radius: 60px;
  padding-left: 24px !important;
  padding-right: 24px !important;
  left: 0px !important;
  position: absolute !important;
  text-align: left !important;
  top: 64px !important;
  transform-origin: 60px 0% !important;
  width: 100% !important;
  z-index: 2 !important;
  opacity: 0 !important;
  transform: scale(0.4, 0.727273) translate(60px, -122px) !important;
  pointer-events: none !important;
  visibility: hidden !important;
  will-change: transform, opacity !important;
  transition: -ms-transform 150ms ease 0s, -webkit-transform 150ms ease 0s,
    transform 150ms ease 0s, opacity 50ms ease 20ms, visibility 0ms ease 150ms !important;
  top: 0px !important;
  transform-origin: 50% 0% !important;
  transform: scale(0.35, 0.727273) translateY(-58px) !important;
}
.nav_section_search_mega_open {
  width: 824px;
  height: 109px;
  padding: 25px;
  border-radius: 60px;
  padding-left: 24px !important;
  padding-right: 24px !important;
  left: 0px !important;
  position: absolute !important;
  text-align: left !important;
  top: 64px !important;
  transform-origin: 60px 0% !important;
  width: 100% !important;
  z-index: 2 !important;
  /* pointer-events: none !important; */
  transition: -ms-transform 150ms ease 0s, -webkit-transform 150ms ease 0s,
    transform 150ms ease 0s, opacity 50ms ease 20ms, visibility 0ms ease 150ms !important;
  top: 0px !important;
  transform-origin: 50% 0% !important;
}
.nav_section_search_mega > div,
.nav_section_search_mega_open > div {
  margin-bottom: 25px;
}
@media (max-width: 967px) and (orientation: landscape) {
  .book_demo{
    display: none !important;
  }
  .checkLink{
    visibility: visible !important;
  }
}
.book_demo {
  font: 600 18px/26px "Poppins";
  letter-spacing: 0px;
  color: #ffffff;
  border-radius: 24px;
  background-color: #29a6ed;
  border: none;
  min-width: 153px;
  height: 48px;
  padding: 0 15px;
  margin-right: 16px;
}
.book_a_demo_form {
  width: 800px;
}
.book_a_demo {
  width: 1200px;
  overflow: hidden;
}
.menu_btn::after {
  content: none !important;
}
.btn-check:active + .menu_btn,
.btn-check:checked + .menu_btn,
.menu_btn.active,
.menu_btn:active,
.menu_btn:focus,
.show > .menu_btn.dropdown-toggle {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.custom_navbar .menu_dropdown {
  top: 16px !important;
  padding: 24px 32px;
  box-shadow: 0px 15px 30px #0000001a;
  border-radius: 18px;
  background-color: #ffffff;
  text-align: center;
}
.menu_dropdown .dropdown-item {
  font: 500 16px/20px "Poppins";
  letter-spacing: 0px;
  color: #07141a;
  padding: 7px 0;
  z-index: 9;
}
.menu_dropdown .dropdown-item:active {
  color: #07141a !important;
  background-color: transparent !important;
}
.menu_dropdown_profile {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin: 0 auto 15px;
  display: block;
}
.menu_dropdown_name {
  font: normal 16px/20px "Poppins";
  letter-spacing: 0px;
  color: #2f2233;
  margin-bottom: 0.5px;
  padding-bottom: 15px !important;
  border-bottom: 1px solid #e9e9e9;
}
.menu_dropdown_mail {
  font: normal 12px/14px "Poppins";
  letter-spacing: 0px;
  color: #645767 !important;
}
.menu_dropdown_auth {
  padding-top: 15px !important;
  border-top: 1px solid #e9e9e9 !important;
  margin-top: 10px;
}
/* Nav Bar End */
.main_header {
  max-width: 824px;
  margin: 0 auto;
  text-align: center;
  pointer-events: none;
}
.main_header .m-width {
  max-width: 824px;
  width: 100%;
}
.main_header_heading {
  font: 500 72px/89px "General Sans";
  color: #2f2233;
  margin-bottom: 33px;
}
.header_menu_video .main_header_heading {
  color: #ffffff;
}
.main_header_para {
  font: normal 20px/22px "Poppins";
  color: #645767;
  margin-bottom: 32px;
}
.header_menu_video .main_header_para {
  color: #ffffff;
}
.scroll_down {
  position: absolute;
  bottom: 27px;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
}
.scroll_down_object {
  width: 20px;
  height: 30px;
  border: 1px solid #645767;
  position: relative;
  margin: 0 auto 4px;
  border-radius: 10px;
}
.scroll_down_object span {
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: #ffffff;
  left: 50%;
  top: 5px;
  transform: translate(-50%, 0);
  animation: MoveUpDown 1.5s linear infinite;
  position: absolute;
}
@keyframes MoveUpDown {
  0%,
  100% {
    top: 5px;
  }
  50% {
    top: 18px;
  }
}
.scroll_down_title {
  font: 500 14px/18px "Poppins";
  color: #2f2233;
  margin-bottom: 0;
}
.scroll_down_text {
  font: normal 12px/16px "Poppins";
  color: #786e82;
  margin-bottom: 0;
}
.header_menu_video .scroll_down_title,
.header_menu_video .scroll_down_text {
  color: #ffffff;
}

/* Big Availability Button */
.main_header_big_availability_btn_super_text{
  color: #ffffff;
  animation: super_text_flash 5s ease-in-out 4s infinite;
  /* transition: all 0.3s ease-in-out; */
}
@keyframes super_text_flash {
  0% { transform: scale(1);
    color: #fff; }
  2% { transform: scale(0.95);
    color: #c2c2c2; }
  4% { transform: scale(1);
    color: #fff; }
}

.main_header_big_availability_btn{
  padding: 35px 70px;
  border-radius: 60px;
  font: normal normal 500 24px "Poppins";
  background-color: #29a6ed;
  border: none;
  color: #ffffff;
  text-transform: uppercase;
  animation: wiggle_main 5s ease-in-out 4s infinite;
}
@keyframes wiggle_main {
  0% {
    transform: scale(1);
    color: #fff;
  }
  2% {
    transform: scale(0.95);
    color: #c2c2c2 ;
  }
  4% { transform: scale(1);
    color: #fff;
  }
  5% { transform: translateX(0); }
  15% { transform: translateX(-15px); }
  25% { transform: translateX(15px); }
  35% { transform: translateX(-10px); }
  45% { transform: translateX(10px);  }
  55% { transform: translateX(-7.5px); }
  65% { transform: translateX(7.5px); }
  75% { transform: translateX(-5px); }
  85% { transform: translateX(5px); }
  95% { transform: translateX(-2.5px); }
  100% {transform: translateX(0); }
}
/* .button {
  cursor: pointer;
  background-color: #29a6ed;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  transition: all 0.3s ease-in-out; */
/* } */
/* Search Modal for Web  */
.cutom_search_modal_btn_container{
  position: relative;
    left: -30px;
    overflow: hidden;
    min-width: 200px;
    transition: all .8s ease-in;
}
.cutom_search_modal_btn{
  background: #29a6ed;
    border: none;
    font: normal normal 500 22px "Poppins";
    color: white;
    padding: 20px;
    min-width:120px;
    height: 100%;
    border-radius: 0 80px 80px 0px;
    position: relative;
}
/* .cutom_search_modal_btn span{
  animation: showUp 0.5s ease-in;
} */
.cutom_search_modal_btn img{
  /* animation: hideFromDown 0.5s ease-out; */
  position: absolute;
  margin:0 auto;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 0;
  opacity: 0;
}
.cutom_search_modal_btn:hover span{
  animation: hideUp 0.5s ease-out ;
  opacity: 0;
}
.cutom_search_modal_btn:hover img{
  animation: showFromDown 0.5s ease-in ;
  opacity: 1;
  top:0;
  margin:auto
}

.showSearchText{
  background-color: #29a6ed !important;
  line-height: initial;
  font: normal normal 500 30px "Poppins";
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 30px;
}

.img_btn.showSearchTex:focus, .img_btn.showSearchTex:hover {
  background-color: #29a6ed !important;
  line-height: initial;
}

.main_header_big_availability_btn_disable{
  display: none;
}

.show_search_modal_for_band_availability :global(.modal-content){
  background-color: transparent !important;
  
}
.show_search_modal_for_band_availability :global(.modal-content) :global(.modal-body){
  display: contents;
}

.search_modal_for_web_backdrop{
  opacity: 0.3 !important;
}

.search_modal_for_web{
  max-width: 800px;
  min-width: 670px;
}

.search_modal_for_web :global(.modal-content) {
  border-radius: 80px;
  display: flex;
  flex-direction: row;
  position: relative;
  border: none;
}
.search_modal_for_web :global(.modal-content) .search_box {
  min-width: 690px
}

.search_for_web_close_btn{
  position: fixed;
  right: 23px;
  top: 10px;
  background-color: transparent;
  border-radius: 50%;
  width: fit-content;
  text-align: center;
  margin: 0 auto;
  padding: 0 18px;
  font: normal normal normal 40px "Poppins";
  border: none;
  color: #646464;
}

/* Search Box  */
.search_box {
  padding: 25px;
  background-color: #ffffff;
  border-radius: 60px;
  max-width: 778px;
  margin: 0 auto;
  transition: all 0.2s linear;
  opacity: 1;
}
.main_header .search_box {
  box-shadow: 0 15px 60px rgb(24 80 136 / 20%);
}
.header_menu_search .main_header .search_box {
  visibility: hidden !important;
  opacity: 0;
  transition: all 0.2s linear;
  transform: scale(-0.65, 0.727273) translateY(-232px) !important;
}
.header_menu_search .main_header .search_box * {
  visibility: hidden !important;
  transition: all 0.1s linear;
}
.search_box_field {
  padding: 0 20px;
  text-align: left;
  border-right: 1px solid #edecec;
}
.search_box_field:last-child {
  border-right: none;
  width: 200px;
}
.search_box_field_label {
  width: 100%;
  font: 500 14px/16px "Poppins";
  color: #645767;
  margin-bottom: 9px;
}
.search_box_field_label {
  width: 100%;
  font: 500 14px/16px "Poppins";
  color: #645767;
  margin-bottom: 9px;
}
.search_box_field_dropdown.ant-picker,
.search_box_field .ant-select,
.search_box_field .ant-select-selector,
.search_box_field
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.search_box_field
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.search_box_field .ant-select input,
.search_box_field .ant-select .ant-select-selection-placeholder,
.search_box_field .ant-select .ant-select-selection-item,
.search_box_field_dropdown.ant-picker-focused,
.search_box_field_dropdown.ant-picker input,
.search_box_field input {
  border: none !important;
  outline: 0;
  box-shadow: none;
  width: 100%;
  font: 500 16px/22px "Poppins";
  color: #2f2233;
  margin-bottom: 0;
  padding: 0 !important;
  align-items: center;
}
.ant-select-item-option-content,
.pac-container .pac-item,
.pac-container .pac-item .pac-matched {
  font: 500 13px/30px "Poppins";
  color: #2f2233;
}
.search_box_field_dropdown_label {
  margin-right: 40px;
}
.search_box_field_input {
  position: relative;
  font-weight: 600;
}
.search_box_field .ant-select {
  padding-right: 40px !important;
}
.search_box_field_input img {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
}
.ant-picker-panel *,
.pac-container * {
  font-family: "Poppins";
}
.search_box_field_wrapper .ant-picker-panel-container,
.pac-container,
.search_box_field_drop {
  border-radius: 10px !important;
  z-index: 9999;
}

@media (min-width: 768px) {
  .search_box_field .search_box_field_dropdown input{
    width: fit-content;
  }
}

/* Search Box End  */

/* Account Modal */
.account_modal {
  width: 680px !important;
}
.account_modal :global(.ant-modal-body) {
  padding: 0;
  display: flex;
  justify-content: center;
}
.account_modal_banner > div {
  height: 100%;
}
.account_modal_banner {
  width: 280px;
}
.account_modal_banner img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.account_modal_content {
  width: 400px;
  padding: 32px;
  min-height: 480px;
}
.account_modal_heading {
  font: 600 24px/30px "Poppins";
  color: #2f2233;
  margin-bottom: 4;
}
.account_modal_option {
  font: normal 12px/18px "Poppins";
  color: #353b3b;
  margin-bottom: 24px;
}
.account_modal_option_link,
.account_modal_option_link * {
  font: normal 12px/18px "Poppins";
  color: #29a6ed;
  font-weight: 500;
  margin-bottom: 0;
}
.account_modal_option_link_upper {
  text-transform: uppercase;
}
.account_modal_option_link_right {
  margin-left: 4px;
}
.account_modal_option_link_text {
  color: #353b3b;
  margin-right: 3px;
}
.account_modal_option_link_terms {
  margin-bottom: 24px;
}
.account_modal_option_link_resend {
  margin-bottom: 12px;
  display: inline-block;
}
.account_modal .input_section {
  margin-bottom: 24px;
}
.account_modal .input_section_otp {
  margin-bottom: 20px;
}
.account_modal .input_field {
  border: 1px solid #d7d1dd;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 34px;
  padding: 0 20px;
}
.account_modal .input_field input,
.account_modal .input_field input:focus {
  padding: 0;
  font: normal 12px/16px "Poppins";
  color: #786e82;
  border: none !important;
  background: transparent !important;
  outline: none;
  box-shadow: none;
  margin-bottom: 0;
}
.account_modal .input_section_otp .input_field {
  border: none;
  padding: 0;
  height: auto;
}
.account_modal .input_section_otp .input_field > * {
  margin: 0 -8px;
}
.account_modal .input_section_otp .input_field .otp_input_form {
  border: 1px solid #d7d1dd;
  border-radius: 25px;
  width: 48px;
  height: 48px;
  margin: 0 8px;
  display: flex;
}
.account_modal .input_section_otp .input_field .otp_input_form input {
  font: 500 14px/18px "Poppins";
  width: 100% !important;
}
.account_modal .input_section_otp .input_field .otp_input_form span {
  display: none;
}
.account_modal .input_field :global(.react-tel-input .form-control),
.account_modal .input_field :global(.react-tel-input .form-control:focus) {
  font: normal 12px/16px "Poppins" !important;
  color: #786e82;
  border: none !important;
  background: transparent !important;
  outline: none;
  box-shadow: none;
  margin-bottom: 0;
  padding-left: 42px !important;
}
.account_modal .input_field :global(.react-tel-input .flag-dropdown),
.account_modal .input_field :global(.react-tel-input .flag-dropdown.open),
.account_modal
  .input_field
  :global(.react-tel-input .flag-dropdown.open .selected-flag),
.account_modal .input_field :global(.react-tel-input .selected-flag:hover),
.account_modal .input_field :global(.react-tel-input .selected-flag:focus) {
  background-color: transparent !important;
  border: none !important;
}
.account_modal .input_field .eye_icon {
  margin-left: 10px;
  width: 13px;
  height: auto;
}
.account_modal .login_btn {
  min-width: 85px;
  height: 36px;
  padding: 0 14px;
  font: 500 14px/18px "Poppins";
  background-color: #29a6ed;
  color: #ffffff;
  border: none;
  border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.input_section_register,
.input_section_register span,
.input_section_register a {
  font: 500 12px/18px "Poppins";
  letter-spacing: 0px;
  color: #2f2233;
  margin-bottom: 24px;
}
.input_section_register a {
  color: #29a6ed;
}

span.event_planer_check_text {
  font: 500 12px/18px "Poppins";
  margin-left: 10px;
}
.register_checkbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}
.register_checkbox input[type="checkbox"] {
  zoom: 1.5;
}
.signin_eventplanner h2 {
  font: normal normal 600 24px/30px "Poppins";
  color: #2f2233;
  text-align: center;
}
.signin_eventplanner {
  width: 510px !important;
  border-radius: 10px;
}
.signin_eventplanner .ant-modal-body {
  padding: 20px;
  border-radius: 10px;
}
.signin_eventplanner.account_modal .ant-modal-body {
  display: block;
}
.signin_eventplanner_choose {
  display: flex;
  justify-content: center;
}
.signin_eventplanner_btn {
  text-align: center;
}
.signin_eventplanner_btn button {
  background: #29a6ed 0% 0% no-repeat padding-box;
  border-radius: 24px;
  opacity: 1;
  font: normal normal normal 14px/18px "Poppins";
  letter-spacing: 0px;
  color: #ffffff;
  border: none;
  padding: 10px 15px;
}
.account_modal_social_text {
  font: 500 12px/16px "Poppins";
  color: #786e82;
  margin-right: 10px;
}
.account_modal_social_btn {
  margin-right: 12px;
}
/* Account Modal End */
.custom_navbar .menu_open_field {
  display: none !important;
}
.main_header .nav_section_search_mini_btn {
  width: 349px;
  /* padding: 5px 7px; */
  padding: 0px;
  margin-bottom: 50px;
}
@media (max-width: 369px) {
  .main_header .nav_section_search_mini_btn {
    width: 300px;
  }
}
@media (max-width: 313px) {
  .main_header .nav_section_search_mini_btn {
    width: 250px;
  }
  .nav_section_search_min_btn_text_new p:first-child{
    font-size: 10px;
  }
  .nav_section_search_min_btn_text_new p.bigger_text{
    font-size: 12px;
  }
}

.mobile_flash_text {
  animation: super_text_flash 5s ease-in-out 4s infinite;
}
.main_header .nav_section_search_mini_btn_text {
  font: normal 15px/30px "Poppins";
  letter-spacing: 0px;
  /* color: #645767; */
  color: #ffffff;
  background-color: #29a6ed;
  /* text-align: center; */
}
.main_header .nav_section_search_mini_btn_img img {
  width: 28px;
  position: relative;
  left: -10px;
}

/* Search Modal */
.search_modal .search_box {
  flex-direction: column;
  align-items: flex-start !important;
  width: 100%;
}
.search_modal :global(.modal-content), .search_modal :global(.ant-modal-content){
  border-radius: 20px;
}
.search_modal :global(.ant-modal-body){
padding: 1rem;
}
.search_modal :global(.modal-body) > h5{
  text-align: center;
}
.search_modal .search_box_field_wrapper {
  flex-direction: column;
  align-items: flex-start !important;
  width: 100%;
}
.search_modal .search_box_field_wrapper .search_box_field {
  padding: 10px 0 25px;
  border-right: 0;
  width: 100%;
}
.search_modal .info_icon {
  position: absolute;
  top: 10px;
  left: 10px;
}
.search_btn span {
  display: none;
}
.search_modal .search_btn span {
  display: inline-block;
  color: #ffffff;
  font: 600 17px/22px "Poppins";
  /* margin-right: 10px; */
  margin-left: 8px;
}
.search_modal .search_btn {
  display: block;
  margin: 0 auto;
}
.search_modal .search_btn,
.search_modal .search_btn:hover,
.search_modal .search_btn:focus {
  background-color: #29a6ed!important;
  color: #ffffff;
  font: 600 17px/22px "Poppins";
  border-radius: 30px;
  padding: 4px 8px;
}
.search_modal .search_btn img {
  width: 28px;
  height: auto;
}
.search_modal_close {
  font: 600 17px/22px "Poppins";
  position: absolute;
  top: 10px;
  right: 10px;
  line-height: 22px !important;
}
.search_modal  :global(.pac-container),
.search_modal  :global(.ant-select-dropdown) {
  z-index: 9999!important;
}
.search_modal :global(.ant-select-dropdown){
  z-index: 9999!important;
}

/* Search Modal End */
.ant-picker-dropdown *,
.pac-container *,
.ant-select-dropdown * {
  font-family: "Poppins";
  z-index: 9999;
}

span.event_planer_check_text {
  font: 500 12px/18px "Poppins";
  margin-left: 10px;
}

/* Cursor */
.cursor {
  /* width: 40px; */
  /* height: 40px; */
  /* border: 2px solid #fefefe; */
  /* border-radius: 100%; */
  position: fixed;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 9999;
  /* mix-blend-mode: difference; */
}
/* Curson */

@media (min-width: 992px) {
  .header .custom_navbar .nav_section {
    display: none !important;
  }
  .header .custom_navbar .nav_section_home {
    display: flex !important;
  }
  .header.header_menu_search .custom_navbar .nav_section_home {
    display: none !important;
  }
  .header.header_menu_search .custom_navbar .nav_section {
    display: flex !important;
  }
  .main_header_heading {
    min-width: 824px;
  }
  /* .custom_navbar::after{
      background-color: transparent !important;
    } */
  .custom_navbar_open::after {
    transform: scaleY(2.6) !important;
  }
  .nav_section_search_mini_btn_mobile {
    display: none;
  }
  .header_logo{
    width:170px;
  }
}
@media (max-width: 992px) {
  .main_header_big_availability_btn{
    display: none;
  }
  .search_box_field_input :global(.ant-select){
    width: 100%;
  }
  .custom_navbar.navbar-light .navbar-nav .nav_link {
    padding: 20px;
  }
  .custom_navbar .nav_section_search {
    display: none;
  }
  .header.header_menu_search .nav_section_search_mini_btn_mobile {
    display: block;
  }
  .header.header_menu_search .main_header .nav_section_search_mini_btn_mobile {
    display: none;
  }
  .main_header .search_box,
  .main_header_para {
    display: none !important;
  }
  .main_header_heading {
    font: 500 46px/50px "Poppins";
    margin-bottom: 23px;
  }
  .header_video_play_btn {
    bottom: 150px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .header_video_btn_text {
    bottom: 100px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .custom_navbar .nav_section {
    padding: 26px 64px;
  }
  .book_a_demo{
    width: 90%;
  }
  .book_a_demo_form{
    width: 90%;
  }
}
@media (max-width: 967px) and (orientation: landscape) {
  .custom_navbar .nav_section {
    padding: 16px 24px 0px 16px !important;
    height: 98px !important;
  }
  .nav_section_search_mini_btn {
    padding: 7px;
    /* background-color: #ffffff; */
    border-radius: 40px;
    border: none;
    width: 430px !important;
    max-width: 100%;
    position: relative;
    top: -61px;
    left: 18px;
  }
  .header.header_menu_search .nav_section_search_mini_btn_mobile {
    display: block;
    height: 0px !important;
  }
  .custom_navbar .nav_section_search_mini_btn_mobile {
    width: 100%;
    margin: 0px !important;
  }

  .header.header_menu_bg .main_logo.remove_unused_logo > div:nth-of-type(2), 
  .header.header_menu_bg .main_logo.remove_unused_logo > span:nth-of-type(2),
  .header.header_menu_video .main_logo.remove_unused_logo > span:nth-of-type(2),
  .header.header_menu_video .main_logo.remove_unused_logo > span:nth-of-type(2) {
    display: inline-block !important;
  }
  .header.header_menu_bg .header_logo,
.header.header_menu_video .header_logo {
  visibility: visible;
  opacity: 1;
  height: 50px !important;
}
.main_header_heading {
  font: 500 46px/50px "Poppins";
  margin-bottom: 70px !important;
}
.custom_navbar .menu_dropdown {
  top: -9px  !important;
  padding: 10px 20px !important;
  box-shadow: 0px 15px 30px #0000001a;
  border-radius: 18px;
  background-color: #ffffff;
  text-align: center;
  height: 250px;
  overflow: auto;
}
.featured_video_content_btn{
 margin-bottom: 20px !important;
}
}
@media (max-width: 767px) {
  .account_modal ::global(.ant-modal-content) {
    max-width: 500px;
    margin: 0 auto;
  }
  .account_modal_content {
    width: 100%;
  }
  .account_modal_banner {
    display: none;
  }
  .header_video_play_btn {
    bottom: 150px;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .header_video_btn_text {
    bottom: 100px;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
@media (max-width: 600px) {
  .custom_navbar .nav_section {
    padding: 25px 24px;
  }
  .main_header_heading {
    font: 500 26px/32px "Poppins";
    margin-bottom: 23px;
  }
  .main_logo {
    width: 87px;
    z-index: 9;
  }
  .book_demo {
    font: 600 12px/14px "Poppins";
    letter-spacing: 0px;
    color: #ffffff;
    border-radius: 24px;
    background-color: #29a6ed;
    border: none;
    min-width: 105px;
    height: 27px;
    padding: 0 10px;
    margin-right: 16px;
  }
  .menu_btn img {
    width: 32px;
  }
  .custom_navbar .nav_section_search_mini_btn_text {
    font: normal 12px/14px "Poppins";
  }
  .nav_section_search_mini_btn {
    padding: 5px 7px;
  }
}
@media (max-width: 480px) {
  .book_a_demo {
    margin-bottom: 100px;
    overflow: scroll;
  }
  .book_a_demo_form {
    margin-bottom: 100px;
    overflow: scroll;
  }
  .demo_select {
    height: 200px;
    overflow: scroll;
  }
  .header_logo{
    width: 136px;
  }
}
@media (max-width: 360px) {
  .header_logo{
    width: 124px;
  }
}
/* .updated_top_search_blade_mini_btn {
  background-color: #29a6ed;
  padding: 12px;
}
.updated_top_search_blade_mini_btn .nav_section_search_mini_btn_text{
  color: white;
  text-align: center;
  font: 500 18px "Poppins";
  letter-spacing: 1px;
  animation: bounce 2s  infinite;
  transform-origin: center bottom;
} */
 .animate__slideInDown{
  --animate-duration: 1.2s !important;
  --animate-delay: 2.6s !important;
}

.zoom_in_and_out{
  animation: zoom-in-zoom-out 6s ease infinite;
}
.move_left_to_right{
  animation: moveRight 6s ease-out infinite;
}
@keyframes shine {
  0%,100%{
    text-shadow: none;
    }
  50% {
    text-shadow: 0 0 5px #ffffff6e, 0 0 10px #ffffff5f, 0 0 20px #ffffffaf;
  }

}
@keyframes moveRight {
  0%, 100%{
    transform: translateX(0);
    }
  50% {
    transform: translateX(50%);
  }

}
@keyframes hideUp{
  0%{
    opacity: 1;
  }
  30%{
    opacity: 0.8;
    font-size: 16px;
  }
  50%{
    opacity: 0.5;
    font-size: 12px;
  }
  70%{
    opacity: 0.5;
    font-size: 8px;
  }
  100%{
    opacity: 0;
  }
}
@keyframes showUp{
  0%{
    opacity: 0;
  }
  30%{
    opacity: 0.3;
    font-size: 16px;
  }
  50%{
    opacity: 0.5;
    font-size: 12px;
  }
  70%{
    opacity: 0.8;
    font-size: 16px;
  }
  100%{
    opacity: 1;
  }
}
@keyframes showFromDown{
  0%{
    opacity: 0.3;
    bottom:0
  }
  50%{
    opacity: 0.5;
    bottom:30px
  }
  100%{
    opacity: 1;
    
  }
}
@keyframes hideFromDown{
  0%{
    opacity: 1;
  }
  50%{
    opacity: 0.5;
    bottom:60px
  }
  100%{
    opacity: 0;
    bottom:0
  }
}
/* @keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.5, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes bounce {
  from,
  20%,
  53%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -8px, 0) scaleY(1);
  }

  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -6px, 0) scaleY(.9);
  }

  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0) scaleY(0.8);
  }

  90% {
    transform: translate3d(0, -4px, 0) scaleY(0.95);
  }
}
@keyframes horizontal-shaking {
  0%,100% { transform: translateX(0) }
  5% { transform: translateX(5px) }
  10% { transform: translateX(-5px) }
  15% { transform: translateX(5px) }
 } */